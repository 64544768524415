import "./home.css";

import { Link, useLocation } from "react-router-dom";
import { SWButton, SWLink } from "../components/components";

import { Carousel } from "react-responsive-carousel";
import { TeamList } from "../Teams/TeamList";
import body from "../img/icon-body.png";
import khc from "../img/clinics/khc.png";
import kyle from "../img/coaches/kyle.jpg";
import mind from "../img/icon-mind.png";
import socal from "../img/heroimgs/socalcup.png";
import spirit from "../img/icon-spirit.png";
import staff from "../img/heroimgs/staff.jpg";
import { useEffect } from "react";
import video from "../img/webvideo.mp4";
import wbv from "../img/wbv-logo.jpg";
import y2023t16 from "../img/teams/2023-16.jpg";

const HeroImgs = () => {
  return (
    <div className="heroImgs">
      <a
        href="/about"
        className="heroImg"
        style={{ backgroundImage: `url(${kyle})` }}
      >
        <h2>Who We Are</h2>
      </a>
      <a
        href="/staff"
        className="heroImg"
        style={{ backgroundImage: `url(${staff})` }}
      >
        <h2>Our Staff</h2>
      </a>
      <a
        href="/teams"
        className="heroImg"
        style={{ backgroundImage: `url(${y2023t16})` }}
      >
        <h2>Our Teams</h2>
      </a>
    </div>
  );
};

function Home() {
  const location = useLocation();
  useEffect(() => {
    if (window.beholdWidgets !== undefined) {
      window.beholdWidgets.initialize();
    }
  }, [location]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div className="landingcontainer">
        <div className="landingimg">
          <video
            src={video}
            autoPlay={true}
            muted
            controls={false}
            loop={true}
            playsInline
          />
        </div>
        <div className="imgoverlay">
          <h1>Sidewinder Volleyball Academy</h1>
          <h5>Tucson, AZ Club Volleyball</h5>
          <Link to={"/contact"}>
            <SWButton content={"Contact Us"} />
          </Link>
        </div>
      </div>
      <div className="content" style={{ textAlign: "center" }}>
        <HeroImgs />
        <hr />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h1 style={{ marginBottom: 0 }}>Register for Tryouts!</h1>
          <h4 style={{ marginBottom: 4 }}>Boy's Fall Tryouts</h4>
          <p style={{ margin: 0 }}>
            Email us if you missed tryouts, we still have a few spots open in
            certain age groups.
          </p>
          <h4 style={{ marginBottom: 4 }}>Girl's Tryouts</h4>
          <p style={{ margin: 0 }}>
            Tryouts on November 10th (14 and under) and November 30th (15-18u).
            Registration opens in September.
          </p>
        </div>
        <hr />
        <h1>
          Follow us on{" "}
          <a
            href="https://www.instagram.com/sidewinder_va"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram!
          </a>
        </h1>
        <figure data-behold-id="X955tRQNhaRc8RDAfQGI"></figure>
        <hr />
        <h1 style={{ margin: "32px 0" }}>
          At Sidewinder, we train the whole athlete.
        </h1>
        <div className="trainwhole">
          <div className="trainpart">
            <img src={mind} className="homeicon" alt="heart icon" />
            <h3>Mind</h3>
            <p>
              We take pride in our club's unique atmosphere, one that fosters a
              true sense of family within a highly competitive environment.
            </p>
          </div>
          <div className="trainpart">
            <img src={body} className="homeicon" alt="player icon" />
            <h3>Body</h3>
            <p>
              We strive to teach the fundamentals of volleyball at their highest
              level, to maximize the potential of each of our athletes.
            </p>
          </div>
          <div className="trainpart">
            <img src={spirit} className="homeicon" alt="confetti icon" />
            <h3>Spirit</h3>
            <p>
              The ideals of hard work and family are the bedrock of our club,
              and will continue to be what separates our club from the rest.
            </p>
          </div>
        </div>
        <hr />
      </div>
      <a href="/teams">
        <div className="teamImages">
          <Carousel
            swipeable={false}
            autoPlay={true}
            showThumbs={false}
            showArrows={false}
            showIndicators={false}
            infiniteLoop={true}
            transitionTime={1000}
            interval={4000}
            // animationHandler={"fade"}
          >
            {TeamList.filter((t) => (t.image ? true : false))
              .sort((a, b) => (a.teamName < b.teamName ? 1 : -1))
              .map((team, index) => {
                return (
                  <div className="teamSlide">
                    <img
                      src={team.image.src}
                      alt={`${team.year} ${team.season} ${team.teamName} athletes`}
                    />
                    <h4>
                      {team.year} {team.season} {team.teamName}
                    </h4>
                  </div>
                );
              })}
          </Carousel>
          <h2>View All Teams</h2>
        </div>
      </a>
    </div>
  );
}
export default Home;
