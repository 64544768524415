import "./SummerClinic.css";

import { Helmet } from "react-helmet";
import allskills from "../img/clinics/allskills.png";
import blockdefend from "../img/clinics/blockdefend.png";
import hsp1 from "../img/clinics/hsp1.png";
import hsp2 from "../img/clinics/hsp2.png";
import khc from "../img/clinics/khc.png";
import servepass from "../img/clinics/servepass.png";
import setattack from "../img/clinics/setattack.png";

const ClinicView = ({ image, title, dates }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        margin: "16px 0",
        alignSelf: "center",
        border: "1px solid white",
      }}
    >
      <img src={image} style={{ width: "200px" }} />
      <p style={{ margin: "0 4px", textAlign: "center", flex: 1 }}>
        <strong>{dates}</strong> {title}
      </p>
    </div>
  );
};

function SummerClinic() {
  return (
    <div className="content">
      <Helmet>
        <title>Summer Clinics and Training</title>
        <meta
          name="description"
          content="About our summer trainings, camps and clinics.  We offer volleyball practices and lessons in Tucson, AZ every summer."
        />
      </Helmet>
      <h1>Register for Summer Clinics</h1>
      <p>
        Each session is 3 days, 2 hours each day and boasts a maximum 8-to-1
        player-to-coach ratio. Because of the small training sizes, space is
        limited and camps may fill up fast. Some camps have "beginner" and
        "advanced" sessions available.
      </p>
      <p>
        Each athlete will get a free Sidewinder T-Shirt when they attend their
        first session.
      </p>
      <h2 style={{ textAlign: "center" }}>
        Register through our{" "}
        <a href="http://dashboard.sidewinderva.com">parent portal</a>
      </h2>
      <ClinicView
        image={servepass}
        dates={"May 20th - May 22nd, 6-8pm:"}
        title={"Serve and Pass (beginner and advanced available)"}
      />
      <ClinicView
        image={setattack}
        dates={"May 28th - May 30th, 2-4pm:"}
        title={"Set and Attack (beginner and advanced available)"}
      />
      <ClinicView
        image={blockdefend}
        dates={"June 3rd - June 5th, 2-4pm:"}
        title={"Block and Defend (beginner and advanced available)"}
      />
      <ClinicView
        image={allskills}
        dates={"June 10th - June 12th, 2-4pm:"}
        title={"All Skills (beginner and advanced available)"}
      />
      <ClinicView
        image={khc}
        dates={"July 15th - July 17th, 2-4pm:"}
        title={
          "Come learn from Kamaile Hiapo! Kamaile currently plays libero for the Atlanta Vibe (#1 Ranked U.S. professional team).  She previously played at U of A and BYU.  This 3 day clinic has limited spots available."
        }
      />
      <ClinicView
        image={hsp1}
        dates={"July 22nd - July 24th, 2-4pm:"}
        title={
          "Last chance to get ready for High School tryouts!  Only open to girls trying out for their upcoming school season."
        }
      />
      <ClinicView
        image={hsp2}
        dates={"July 29nd - July 31st, 2-4pm:"}
        title={
          "Last chance to get ready for High School tryouts!  Only open to girls trying out for their upcoming school season."
        }
      />
      <h2 style={{ textAlign: "center" }}>
        Register through our{" "}
        <a href="http://dashboard.sidewinderva.com">parent portal</a>
      </h2>
    </div>
  );
}
export default SummerClinic;
