import "./style.css";

import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import APVFAQs from "./Home/FAQs/APVFAQs";
import About from "./Home/About";
import BoysTryouts from "./Tryouts/Tryouts/BoysTryouts/BoysTryouts";
import Contact from "./Home/Contact/contact";
import FAQs from "./Home/FAQs/FAQs";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import { Helmet } from "react-helmet";
import Home from "./Home/Home";
import Links from "./Home/Links/Links";
import PageNotFound from "./PageNotFound";
import Practices from "./Members/Practices/Practices";
import SafeSport from "./Members/SafeSport/SafeSport";
import Staff from "./Home/Staff/Staff";
import Store from "./store/Store";
import StoreCancel from "./store/StoreCancel";
import StoreSuccess from "./store/StoreSuccess";
import SummerClinic from "./Summer/SummerClinic";
import Teams from "./Teams/Teams";
import Tournaments from "./Members/Tournaments/Tournaments";
import TryoutProcess from "./Tryouts/TryoutProcess/TryoutProcess";
import Tryouts from "./Tryouts/Tryouts/GirlsTryouts/Tryouts";
import { useEffect } from "react";

export const MyRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      {/* About Us */}
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/staff/" element={<Staff />} />
      <Route path="/staff/:selSeason/:selCoach" element={<Staff />} />
      <Route path="/staff/:selSeason/:selSub/:selCoach" element={<Staff />} />
      <Route path="/teams" element={<Teams />} />
      <Route path="/teams/:selSeason/:selTitle" element={<Teams />} />
      <Route path="/teams/:selSeason/:selSub/:selTitle" element={<Teams />} />
      <Route
        path="/teams/:selYear/:selSeason/:selSub/:selTitle"
        element={<Teams />}
      />
      <Route path="/faqs" element={<FAQs />} />
      <Route path="/apvfaqs" element={<APVFAQs />} />
      {/* Training */}
      <Route path="/summerclinics" element={<SummerClinic />} />
      {/* Tryouts */}
      <Route path="/tryouts" element={<Tryouts />} />
      <Route path="/boystryouts" element={<BoysTryouts />} />
      <Route path="/tryoutprocess" element={<TryoutProcess />} />
      {/* Members */}
      <Route path="/practices" element={<Practices />} />
      <Route path="/tournaments" element={<Tournaments />} />
      <Route path="/safesport" element={<SafeSport />} />
      <Route path="/links" element={<Links />} />
      {/* Store */}
      <Route path="/apparel" element={<Store />} />
      <Route path="/store" element={<Store />} />
      <Route path="/storecancel" element={<StoreCancel />} />
      <Route path="/storesuccess" element={<StoreSuccess />} />
      {/* 404 Error */}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

function App() {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://w.behold.so/widget.js";
    script.type = "module";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="App">
      <Helmet>
        <title>Sidewinder Volleyball Academy</title>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="A Tucson, Arizona Volleyball Club.  Tucson volleyball training, clinics, camps and club teams."
        />
      </Helmet>
      <div className="mainContainer">
        {/* <div className='topBanner'>
        <h4>Open Gym TODAY!! Saturday, Nov 19th 3-5pm at Sporting Chance Center.</h4>
        15U-18U players are welcome, call Andrew Adair for more info. 520-850-3467
      </div> */}
        <Router>
          <Header></Header>
          <div className="appContainer">
            <MyRoutes />
          </div>
          <Footer />
        </Router>
      </div>
    </div>
  );
}

export default App;
