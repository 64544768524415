import "./Staff.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { useEffect, useState } from "react";

import { Carousel } from "react-responsive-carousel";
import CoachHeader from "./CoachHeader";
import CoachView from "./CoachView";
import Coaches from "./Coaches";
import { Helmet } from "react-helmet";
import { TeamList } from "../../Teams/TeamList";
import { useParams } from "react-router-dom";

function Staff() {
  const { selSeason, selSub, selCoach } = useParams();
  const [slide, setSlide] = useState(0);

  const [year, setYear] = useState(2024);
  const [season, setSeason] = useState(selSeason ?? "Boys");
  const [sub, setSub] = useState(selSub ?? "Fall");

  const teams = TeamList.filter(
    (t) =>
      t.season === season &&
      (season === "Boys" ? t.sub === sub : true) &&
      t.year === year
  );

  const directors = [Coaches.aadair, Coaches.kwells];

  const hCoachArrs = [
    ...teams.map((t) => {
      return t.heads
        ? t.heads
            .filter((c) => c.type !== "Director")
            .map((c) => {
              c.team = t;
              return c;
            })
        : [];
    }),
  ];
  const aCoachArrs = [
    ...teams.map((t) => {
      return t.assistants
        ? t.assistants
            .filter((c) => c.type !== "Director")
            .map((c) => {
              c.team = t;
              return c;
            })
        : [];
    }),
  ];

  useEffect(() => {
    const allCoaches = [
      ...directors,
      ...hCoachArrs.flat(1),
      ...aCoachArrs.flat(1),
    ];

    if (selCoach !== undefined) {
      const i = allCoaches.findIndex((coach) => {
        console.log("THIS COACH");
        console.log(coach);
        return (
          coach.name.replaceAll(" ", "").toLowerCase() ===
          selCoach.toLowerCase().replaceAll(",", "")
        );
      });

      setSlide(i);
    }

    return () => {};
  }, []);

  return (
    <div className="content">
      <Helmet>
        <title>Coaches and Staff</title>
        <meta
          name="description"
          content="Our directors, coaches and staff at Sidewinder Volleyball Academy have years of experience with youth volleyball in Tucson, AZ."
        />
      </Helmet>
      <h1>Our Staff</h1>
      <CoachHeader
        slide={slide}
        setSlide={setSlide}
        season={season}
        setSeason={setSeason}
        sub={sub}
        setSub={setSub}
        directors={directors}
        headCoaches={hCoachArrs}
        assistCoaches={aCoachArrs}
      />
      <hr />
      <div>
        <Carousel
          showThumbs={false}
          showArrows={false}
          showIndicators={false}
          infiniteLoop={true}
          selectedItem={slide}
          swipeScrollTolerance={50}
          preventMovementUntilSwipeScrollTolerance={true}
          onChange={(result) => {
            if (result != slide) {
              setSlide(result);
            }
          }}
        >
          {[...directors, ...hCoachArrs, ...aCoachArrs]
            .flat(1)
            .map((coach, index) => {
              return (
                <CoachView
                  coach={coach}
                  season={season}
                  sub={sub}
                  key={coach.name}
                />
              );
            })}
        </Carousel>
      </div>
    </div>
  );
}

export default Staff;
