import { Carousel } from "react-responsive-carousel";
import React from "react";
import group from "../img/store/group.JPG";
import group2 from "../img/store/group2.JPG";
import group3 from "../img/store/group3.JPG";
import group4 from "../img/store/group4.JPG";

const imgs = [group, group2, group3, group4];

const HeroSlide = ({ img }) => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div
        style={{
          backgroundImage: `url(${img})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          borderRadius: "8px",
          height: "100vh",
          maxWidth: "100vw",
          maxHeight: "30vh",
          aspectRatio: "2",
        }}
      />
    </div>
  );
};

const StoreHeader = () => {
  return (
    <div
      style={{
        height: "100vh",
        maxWidth: "100vw",
        maxHeight: "30vh",
        aspectRatio: "2",
        overflow: "hidden",
      }}
    >
      <Carousel
        swipeable={false}
        autoPlay={true}
        showThumbs={false}
        showArrows={false}
        showIndicators={false}
        infiniteLoop={true}
        transitionTime={2000}
        interval={5500}
        showStatus={false}
        // animationHandler={"fade"}
      >
        {imgs.map((img, index) => {
          return <HeroSlide img={img} key={`Hero${index}`} />;
        })}
      </Carousel>
    </div>
  );
};

export default StoreHeader;
