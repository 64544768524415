import React from "react";
import insta from "../img/instagram.png";
import snake from "../img/snake.png";
import youtube from "../img/youtube.png";

const FooterLeft = () => {
  return (
    <div className="lowerLeft">
      <h2>Contact Us</h2>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <img src={snake} />
        <div className="contacts">
          <a href="mailto:Director@SidewinderVA.com">
            <h4>Director@SidewinderVA.com</h4>
          </a>
          <h4>(520) 850-3467</h4>
          <div className="socials blueFilter">
            <a
              href="https://www.youtube.com/channel/UCy1yd8ExnyPqT78KElQex5A"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={youtube} />
            </a>
            <a
              href="https://www.instagram.com/sidewinder_va"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={insta} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterLeft;
