import "./header.css";

import { Link, useLoaderData, useLocation } from "react-router-dom";
import SWButton, { SWLink } from "../components/components";

import logo from "../img/SVATEXT.png";
import menu from "../img/menu.png";
import { useState } from "react";

function Header() {
  const location = useLocation();
  const { pathname } = location;
  return (
    <div
      className="header"
      style={
        pathname == "/store" || pathname == "/apparel"
          ? { paddingTop: "50px" }
          : {}
      }
    >
      <div className="imgcontainer">
        <Link to="/">
          <img src={logo} className="logo" />
        </Link>
      </div>
      <nav className="navbar">
        <ul>
          <li className="dropdown">
            <a href="#" style={{ pointerEvents: "none" }}>
              About Us
            </a>
            <ul>
              <li>
                <SWLink to="/about" content="About Us" />
              </li>
              <li>
                <SWLink to="/contact" content="Contact" />
              </li>
              <li>
                <SWLink to="/staff" content="Staff" />
              </li>
              <li>
                <SWLink to="/teams" content="Teams" />
              </li>
              <li>
                <SWLink to="/faqs" content="FAQs" />
              </li>
            </ul>
          </li>
          <li className="dropdown">
            <a href="#" style={{ pointerEvents: "none" }}>
              Training
            </a>
            <ul>
              <li>
                <SWLink to="/summerclinics" content="Summer Clinics" />
              </li>
            </ul>
          </li>
          <li className="dropdown">
            <a href="#" style={{ pointerEvents: "none" }}>
              Tryouts
            </a>
            <ul>
              <li>
                <SWLink to="/tryouts" content="Girl's Tryouts" />
              </li>
              <li>
                <SWLink to="/boystryouts" content="Boy's Tryouts" />
              </li>
              <li>
                <SWLink to="/tryoutprocess" content="Tryout Process" />
              </li>
            </ul>
          </li>
          <li className="dropdown">
            <a href="#" style={{ pointerEvents: "none" }}>
              Member Links
            </a>
            <ul>
              <li>
                <SWLink to="/practices" content="Practices" />
              </li>
              <li>
                <SWLink to="/tournaments" content="Tournaments" />
              </li>
              <li>
                <SWLink to="/safesport" content="Safe Sport" />
              </li>
              <li>
                <SWLink to="/links" content="USAV Links" />
              </li>
              <li>
                <SWLink to="/apparel" content="Apparel" />
              </li>
            </ul>
          </li>
          <li className="dropdown">
            <a href="/apparel">Apparel</a>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Header;
