import { useEffect, useState } from "react";

import ButtonSelect from "../components/ButtonSelect";
import { Dropdown } from "../components/Dropdown";
import { Helmet } from "react-helmet";
import { TeamList } from "./TeamList";
import { TeamSlider } from "./TeamSlider";
import { useParams } from "react-router-dom";

function Teams() {
  const { selYear, selSeason, selSub, selTitle } = useParams();

  const [slide, setSlide] = useState(0);

  const handleYearChange = (event) => {
    setYear(parseInt(event.target.value));
  };
  const years = [
    ...new Set(
      TeamList.map((t) => {
        return t.year;
      })
    ),
  ].map((y) => {
    return { label: y, value: y };
  });
  const [year, setYear] = useState(2024);

  const handleSeasonChange = (event) => {
    setSeason(event.target.value);
    setSlide(0);
  };
  const seasons = [
    { label: "Boys", value: "Boys" },
    { label: "Girls", value: "Girls" },
  ];
  const [season, setSeason] = useState("Boys");

  const handleSubChange = (event) => {
    setSub(event.target.value);
    setSlide(0);
  };
  const subs = [
    { label: "Summer", value: "Summer" },
    { label: "Fall", value: "Fall" },
  ];
  const [sub, setSub] = useState("Fall");

  useEffect(() => {
    if (selTitle !== undefined) {
      setSeason(selSeason);
      if (selSub !== undefined) {
        setSub(selSub);
      }
      if (selYear !== undefined) {
        setYear(parseInt(selYear));
      }
      const i = TeamList.filter(
        (a) =>
          a.season === selSeason &&
          (selSub ? a.sub === selSub : true) &&
          (selYear ? a.year === parseInt(selYear) : true)
      ).findIndex((team) => team.teamName === selTitle);
      setSlide(i);

      window.scroll({ top: 200, left: 0, behavior: "smooth" });
    }

    return () => {};
  }, []);

  return (
    <div className="content">
      <Helmet>
        <title>Our Teams</title>
        <meta
          name="description"
          content="Our youth volleyball teams practice in Tucson, Arizon to compete around the country."
        />
      </Helmet>
      <h1>Our Teams</h1>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Dropdown
          label="Year:"
          options={years}
          value={year}
          onChange={handleYearChange}
          key={year}
          hideLabel
        />
        <Dropdown
          label="Season:"
          options={seasons}
          value={season}
          onChange={handleSeasonChange}
          key={season}
          hideLabel
        />
        {season === "Boys" ? (
          <Dropdown
            label="Sub:"
            options={subs}
            value={sub}
            onChange={handleSubChange}
            key={sub}
            hideLabel
          />
        ) : null}
      </div>

      <TeamSlider
        teams={TeamList.filter(
          (a) =>
            a.season === season &&
            (season === "Boys" ? a.sub === sub : true) &&
            a.year === year
        )}
        slide={slide}
        setSlide={setSlide}
      />
    </div>
  );
}
export default Teams;
