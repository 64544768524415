import "./footer.css";

import FooterLeft from "./FooterLeft";
import { Link } from "react-router-dom";
import Partners from "./Partners";
import { SWButton } from "../components/components";
import hb from "../docs/2023SeasonHandbook.pdf";
import { useState } from "react";

function Footer() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [submitted, setSubmitted] = useState(false);

  function handleClick() {
    if (email.length > 0 && name.length > 0 && email.includes("@")) {
      setSubmitted(true);
      fetch(
        "https://us-central1-sidewinder-3daf9.cloudfunctions.net/app/subscribe/",
        {
          method: "POST",
          body: JSON.stringify({
            name: name.trim(),
            email: email.trim(),
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      )
        .then(() => {
          console.log("here");
        })
        .catch((err) => {
          setSubmitted(false);
          console.log(err.message);
          setError("Error connecting.  Check internet and try again!");
        });
    } else {
      //Email is invalid
      setSubmitted(false);
      setError("Oops! Please fill all fields and check the email is correct.");
    }
  }

  const onChangeHandlerEmail = (event) => {
    setEmail(event.target.value);
  };
  const onChangeHandlerName = (event) => {
    setName(event.target.value);
  };

  const currentYear = new Date().getFullYear();

  return (
    <div className="footer">
      <div className="newsletterContainer">
        {!submitted ? (
          <div>
            <h2>Join Our Newsletter</h2>
            <p>Get notified when we host clinics, camps, tryouts, and more.</p>
            <div>
              <input
                type="text"
                name="NAME"
                placeholder="Name"
                value={name}
                onChange={onChangeHandlerName}
                required
              />
              <input
                type="email"
                name="EMAIL"
                placeholder="Email Address"
                value={email}
                onChange={onChangeHandlerEmail}
                required
              />
            </div>
            <h4>{error}</h4>
            <SWButton content={"Submit"} onClick={() => handleClick()} />
          </div>
        ) : (
          <div>
            <h3>Email Submitted!</h3>
            <h5>Thanks, you'll get updates from us.</h5>
          </div>
        )}
      </div>
      <div className="lowerFooter rowWrap">
        <FooterLeft />
        <Partners />
      </div>
      <h6>&copy; Sidewinder Volleyball Academy, {currentYear}</h6>
    </div>
  );
}

export default Footer;
