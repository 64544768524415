import { Carousel } from "react-responsive-carousel";
import React from "react";
import azregion from "../img/azregion.png";
import st from "../img/st.png";
import usavolleyball from "../img/usavolleyball.png";
import vla from "../img/vla.webp";

const Partners = () => {
  const imgs = [
    {
      title: "Arizona Region",
      description: "We play in the Arizona Region of USA Volleyball",
      src: azregion,
      link: "https://www.azregionvolleyball.org/",
    },
    {
      title: "USA Volleyball",
      description:
        "We attend the USAV National Championship tournament every year",
      src: usavolleyball,
      link: "https://usavolleyball.org/",
    },
    {
      title: "Stat Together",
      description: "We use Stat Together to live stream all matches",
      src: st,
      link: "https://stattogether.com/",
    },
    {
      title: "VLA",
      description:
        "We are partnered with the Volleyball League of America, USA's professional volleyball league",
      src: vla,
      link: "https://www.usavla.com/",
    },
  ];
  return (
    <div className="lowerRight">
      <h2>Our Partners</h2>
      <Carousel
        swipeable={true}
        autoPlay={true}
        showThumbs={false}
        showArrows={false}
        showIndicators={false}
        infiniteLoop={true}
        transitionTime={1000}
        interval={5000}
        showStatus={true}
        width={"400px"}
      >
        {imgs.map((img, index) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
              key={img.title}
            >
              <a href={img.link} target="_blank" rel="noopener noreferrer">
                <img
                  src={img.src}
                  style={{ height: "100px", width: "auto" }}
                  key={"partner" + index}
                  alt="Sidewinder Partner"
                />
              </a>
              <div style={{ flex: 1 }}>
                <a href={img.link} target="_blank" rel="noopener noreferrer">
                  <h5>{img.title}</h5>
                </a>
                <h6>{img.description}</h6>
              </div>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default Partners;
