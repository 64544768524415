import "./Staff.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { CaptionImage, SWLink } from "../../components/components";

import { TeamList } from "../../Teams/TeamList";

const CoachView = ({ coach, season, sub }) => {
  return (
    <div className="coachView">
      <h2 style={{ marginBottom: 0 }}>{coach.name}</h2>
      <h4 style={{ margin: "4px 0" }}>
        {coach.title ? (
          <>
            {coach.title}
            <br />
          </>
        ) : null}
        {TeamList.filter(
          (t) =>
            t.current &&
            (t.heads.filter((c) => c.name === coach.name).length > 0 ||
              (t.assistants
                ? t.assistants.filter((c) => c.name === coach.name).length > 0
                : false))
        ).map((team) => {
          const ref = `/teams/${team.season}${team.sub ? "/" + team.sub : ""}/${
            team.teamName
          }`;

          return (
            <>
              <SWLink
                to={ref}
                content={`${team.teamName} ${team.season}`}
                key={`${team.teamName} ${team.season}`}
              />{" "}
              {team.heads.filter((c) => c.name === coach.name).length > 0
                ? "Head"
                : "Assistant"}{" "}
              Coach
              <br />
            </>
          );
        })}
      </h4>
      {coach.paragraphs ? (
        <p dangerouslySetInnerHTML={{ __html: coach.paragraphs[0] }} />
      ) : null}
      {coach.image ? (
        <CaptionImage src={coach.image.src} caption={coach.image.caption} />
      ) : null}
      {coach.paragraphs
        ? coach.paragraphs.map((para, index) => {
            return (
              <>
                {index > 0 ? (
                  <p dangerouslySetInnerHTML={{ __html: para }} />
                ) : null}
              </>
            );
          })
        : null}
      <div style={{ fontSize: "12px", color: "gray" }}>
        {TeamList.filter(
          (t) =>
            t.heads.filter((c) => c.name === coach.name).length > 0 ||
            (t.assistants
              ? t.assistants.filter((c) => c.name === coach.name).length > 0
              : false)
        )
          .sort((a, b) => (a.sub && b.sub ? b.sub.localeCompare(a.sub) : 1))
          .sort((a, b) => b.season.localeCompare(a.season))
          .sort((a, b) => a.year - b.year)

          .map((team, index) => {
            const ref = `/teams/${team.year}/${team.season}${
              team.sub ? "/" + team.sub : ""
            }/${team.teamName}`;

            return (
              <>
                {index !== 0 ? <> &#x2022; </> : null}
                <SWLink
                  to={ref}
                  content={`${team.sub ?? ""} ${team.year} ${team.season} ${
                    team.teamName
                  }`}
                  style={{ color: "gray" }}
                />{" "}
                {team.type} Coach
              </>
            );
          })}
      </div>
    </div>
  );
};

export default CoachView;
